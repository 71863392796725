import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { Post } from "utils/axios";
import { handleErrorMsg } from "utils";
import { requestError } from "utils/requestHandler";
import { addLoadingContent, removeLoadingContent } from "reducers/LoadingOverlay";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      error: "",
      query: { pageNumber: 1, pageSize: 10, role: this.props.role },
      memberList: [],
      totalMembers: 0,
      userCapacity: {},
    };

    onChangeHOC = (key, value) => this.setState({ [key]: value });
    load = (param) => {
      if (param) {
        this.props.addLoadingContent();
      } else {
        this.props.removeLoadingContent();
      }
    };

    getMemberList = (query) =>
      Post(`/member/viewMemberList`, query, this.getMemberListSuccess, this.getMemberListError, this.load);
    getMemberListSuccess = (payload) => this.setState({ memberList: payload.Data, totalMembers: payload.Total });
    getMemberListError = (error) => requestError(handleErrorMsg(error));

    getCustomerList = (query) =>
      Post(
        `/member/viewCustomerListSimple`,
        { ...query, customerIC: query.ic, customerIDName: query.name },
        this.getCustomerListSuccess,
        this.getCustomerListError,
        this.load
      );
    getCustomerListSuccess = (payload) => this.setState({ memberList: payload.Data, totalMembers: payload.Total });
    getCustomerListError = (error) => requestError(handleErrorMsg(error));
    getMemberDetails = (id) =>
      Post(
        `/member/getMemberDetail`,
        { memberID: id },
        this.getMemberDetailsSuccess,
        this.getMemberDetailsError,
        this.load
      );
    getMemberDetailsSuccess = (payload) => {
      this.props.onChange(payload.Data);
      this.setState({ error: "" });
    };
    getMemberDetailsError = (error) => {
      this.props.onChange(undefined);
      this.setState({ error: "Member not found" });
    };

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onChangeHOC={this.onChangeHOC}
          getMemberList={this.getMemberList}
          getMemberDetails={this.getMemberDetails}
          getCustomerList={this.getCustomerList}
          getUnitUserCapacity={this.getUnitUserCapacity}
        />
      );
    };
  }

  const mapStateToProps = (state) => ({
    data: state,
    profile: state.ProfileReducer.profile,
  });
  return connect(mapStateToProps, { addLoadingContent, removeLoadingContent })(WithHOC);
};

export default HOC;
