import { Form, Input, Modal, Table, Row, Col, Space } from "antd";
import React, { useEffect, useState } from "react";
import _ from "lodash";

import TechBaseText from "DisplayCoreV2/Text";
import TechBaseButton from "DisplayCoreV2/Button";
import TBSearchField from "DisplayCoreV2/SearchField";
import LoadingOverlay from "components/Indicator/LoadingOverlay";

import WithHOC from "./actions";

const MemberFinder = (props) => {
  const [form] = Form.useForm();
  const { role, profile, memberList, totalMembers, getMemberList, getCustomerList, onChange, value, placeHolder } =
    props;
  const [query, setQuery] = useState({ role: role, pageNumber: 1, pageSize: 10 });
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (profile.Role === "AD") {
      getMemberList(query);
    } else {
      if (role === "C") {
        getCustomerList(query);
      }
    }
  }, [query]);

  useEffect(() => {
    if (showModal) {
      setQuery({ role: role, pageNumber: 1, pageSize: 10 });
    }
  }, [showModal]);

  const columns = [
    {
      title: "Code",
      key: "Id",
      render: (text, original) => original.MemberID ?? original.MemberId,
    },
    {
      title: "Name",
      dataIndex: "Fullname",
      key: "Fullname",
    },
    {
      title: "IC/Passport",
      dataIndex: "IC",
      key: "IC",
    },
  ];

  if (profile.Role !== "AD" && role !== "C") {
    return <></>;
  }

  return (
    <>
      <TBSearchField
        size="large"
        value={value}
        onChange={onChange}
        directValue
        updateSearchValue
        placeholder={placeHolder}
        allowClear
        style={{
          width: "100%",
        }}
        onSearch={() => setShowModal(true)}
      />
      <Modal open={showModal} footer={null} onCancel={() => setShowModal(false)} title="Member Finder">
        <Form
          form={form}
          layout="vertical"
          onFinish={(formData) =>
            setQuery({
              role: role,
              pageNumber: query.pageNumber,
              pageSize: query.pageSize,
              ..._.omitBy(formData, _.isEmpty),
            })
          }
        >
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item name="ic" label={<TechBaseText content="{{IDENTITY_REGISTRATION_NO}}" />}>
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item name="name" label={<TechBaseText content="{{FULL_NAME}}" />}>
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              {/* <Form.Item name="agentID" label={<TechBaseText content="{{AGENT_ID}}" />}>
                <Input size="large" />
              </Form.Item> */}
            </Col>
            <Col xs={24} md={12} className="d-flex align-items-end justify-content-end">
              <Form.Item>
                <Row className="w-100 d-flex footer-btn">
                  <Col>
                    <TechBaseButton
                      size="large"
                      onClick={() => {
                        setQuery({ role: role, pageNumber: 1, pageSize: 10 });
                        form.resetFields();
                      }}
                    >
                      <TechBaseText content="{{RESET}}" />
                    </TechBaseButton>
                  </Col>

                  <Col>
                    <TechBaseButton size="large" htmlType="submit" color="primary">
                      <TechBaseText content="{{SEARCH}}" />
                    </TechBaseButton>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Table
          columns={columns}
          dataSource={memberList}
          onRow={(record) => {
            return {
              onClick: () => {
                onChange(record.MemberId ?? record.MemberID);
                setShowModal(false);
              },
            };
          }}
          pagination={{
            current: query.pageNumber,
            pageSize: query.pageSize,
            total: totalMembers,
            showSizeChanger: true,
            onChange: (page, pageSize) => setQuery({ role: role, pageNumber: page, pageSize: pageSize }),
          }}
        />
        <div className="techbase-button-wrapper-modal">
          <TechBaseButton className="techbase-button-modal" onClick={() => setShowModal(false)}>
            Close
          </TechBaseButton>
        </div>

        {props.loadingContent.length > 0 && <LoadingOverlay />}
      </Modal>
    </>
  );
};

export default WithHOC(MemberFinder);
